.Mission-section {
  width: 100%;
  height: auto;
  background-color: var(--blue);
}
.Mission-section.red {
  background-color: var(--red);
}
.Mission-section.blue {
  background-color: var(--blue);
}
.Mission-section .img-div-left {
  width: 100%;
  height: 350px;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 81% 100%, 0 100%);
}
.Mission-section .img-div-left img {
  width: 100%;
  height: 100%;
}
.Mission-section .col-md-7 {
  position: relative;
  padding: 0 !important;
}

.Mission-section .col-md-7::after {
  position: absolute;
  content: "";
  z-index: 100;
  right: 65px;
  top: -25px;
  width: 30px;
  height: 113%;
  background-color: var(--red);
  transform: rotate(23deg);
}
.Mission-section .col-md-7::before {
  position: absolute;
  content: "";
  z-index: 100;
  right: 40px;
  top: -25px;
  width: 15px;
  height: 113%;
  background-color: var(--red);
  transform: rotate(23deg);
}
.Mission-section .text-section-mission {
  width: 90%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.Mission-section.red .text-section-mission h1 {
  color: #fff;
}
.Mission-section.blue .text-section-mission h1 {
  font-weight: 800;
  color: var(--red);
}
.Mission-section .text-section-mission h4 {
  color: #fff;
  font-weight: 700;
  width: 80%;
  line-height: 35px;
}
.Mission-section .text-section-mission ul {
  list-style: none;
  margin-bottom: 10px;
  padding-left: 15px;
}
.Mission-section .text-section-mission ul li {
  color: #fff;
  position: relative;
  margin-bottom: 10px;
}
.Mission-section .text-section-mission ul li::after {
  position: absolute;
  content: url("./../images/caret.svg");
  top: 2px;
  left: -20px;
  width: 5px;
  height: 5px;
}

.Mission-section .text-section-mission p {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

/* Right */

.Mission-section .img-div-right {
  width: 100%;
  height: 350px;
  clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%);
}
.Mission-section .img-div-right img {
  width: 100%;
  height: 100%;
}
.Mission-section .col-md-7 {
  position: relative;
  padding: 0 !important;
}
.Mission-section .col-md-7.right::after {
  position: absolute;
  content: "";
  z-index: 100;
  left: 14px;
  top: -25px;
  width: 30px;
  height: 113%;
  background-color: var(--red);
  transform: rotate(23deg);
}
.Mission-section .col-md-7.right::before {
  position: absolute;
  content: "";
  z-index: 100;
  left: 54px;
  top: -25px;
  width: 15px;
  height: 113%;
  background-color: var(--red);
  transform: rotate(23deg);
}

/* Right Reverse */

.Mission-section .img-div-reverse {
  width: 100%;
  height: 350px;
  clip-path: polygon(0 0, 81% 0, 100% 100%, 0% 100%);
}
.Mission-section .img-div-reverse img {
  width: 100%;
  height: 100%;
}
.Mission-section .col-md-6 {
  position: relative;
  padding: 0 !important;
}
.Mission-section .col-md-6.reverse::after {
  position: absolute;
  content: "";
  z-index: 100;
  right: 64px;
  top: -25px;
  width: 30px;
  height: 113%;
  background-color: var(--red);
  transform: rotate(340deg);
}
.Mission-section .col-md-6.reverse::before {
  position: absolute;
  content: "";
  z-index: 100;
  right: 40px;
  top: -25px;
  width: 15px;
  height: 113%;
  background-color: var(--red);
  transform: rotate(340deg);
}
