.footer {
  width: 100%;
  height: 400px;
  box-sizing: border-box;
  background-color: var(--blue);
  position: relative;
}
.footer .footer-overlay {
  position: absolute;
  background: url("./../images/footer.png") no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 30px;
}

.footer .footer-overlay .footer-about p {
  font-weight: 500;
  color: #fff;
  margin-top: 20px;
  line-height: 30px;
  width: 90%;
  margin-bottom: 30px;
}

.footer .footer-overlay .link {
  padding-top: 80px;
}
.footer .footer-overlay .link ul {
  padding: 0;
  list-style: none;
}
.footer .footer-overlay .link ul li {
  padding: 5px 20px;
}
.footer .footer-overlay .link ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

.footer .footer-overlay .contact {
  padding-top: 80px;
}

.footer .footer-overlay .contact ul {
  padding: 0;
  list-style: none;
}
.footer .footer-overlay .contact ul li {
  padding: 5px 20px;
}
.footer .footer-overlay .contact ul li a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  font-weight: 700;
}

.footer .footer-overlay .newslatter {
  padding-top: 80px;
}
.footer .footer-overlay .newslatter ul {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.footer .footer-overlay .newslatter ul li {
  padding: 5px;
}
.footer .footer-overlay .newslatter a {
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  font-weight: 700;
}
.footer .footer-overlay .newslatter .form-control {
  padding: 10px;
}
