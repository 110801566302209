.gallary-section {
  width: 90%;
  margin: 0 auto;
  padding: 30px 10px;
  height: auto;
  margin-bottom: 30px;
}
.slick-dots {
  margin-top: 30px;
  position: inherit !important;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 25px !important;
  height: 25px !important;
  border-radius: 100px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
}

.slick-dots li.slick-active button {
  background: var(--red) !important;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 100% !important;
  height: 100% !important;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: rgba(255, 0, 0, 0.4) !important;
}
.slick-dots li button:before {
  color: transparent !important;
}
