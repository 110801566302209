.youth-section {
  width: 100%;
  height: 600px;
  background-color: var(--gray);
}
.youth-section .text-youth-div {
  width: 80%;
  margin: 0 auto;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
}
.youth-section .text-youth-div h1 {
  color: var(--red);
  font-weight: 800;
  font-size: 45px;
}
.youth-section .text-youth-div p {
  font-weight: 600;
  font-size: 18px;
}

.youth-section .img-youth-div {
  width: 70%;
  height: 500px;
  margin: 20px auto;
}
.youth-section .img-youth-div img {
  width: 100%;
}

/* Movement */
.movement-section {
  width: 100%;
  height: 300px;
  background: url("./../images/movement.svg") no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.movement-section h1 {
  font-weight: 800;
  color: #fff;
}
.movement-section p {
  font-weight: 600;
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
}
