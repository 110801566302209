.power-section {
  height: auto;
  padding: 20px;
  min-height: 600px;
}
.power-section h1 {
  text-align: center;
  font-weight: 800;
  color: var(--blue);
  text-transform: capitalize;
  padding: 20px 0;
}
.power-item {
  width: 80%;
  margin: 10px auto;
  height: 350px;
  border: 10px solid var(--blue);
  position: relative;
  border-radius: 20px;
  background: linear-gradient(0deg, #ffffff -0.01%, #dfe0e0 99.99%);
  margin-bottom: 35px;
}
.power-item p {
  margin-top: 30px;
  font-size: 15px;
  font-weight: 600;
  height: 60%;
  text-align: center;
  padding: 20px;
}
.power-item::after {
  content: "";
  width: 20px;
  height: 30px;
  background: linear-gradient(135deg, #00bfff -109.94%, #3d0075 144.03%);
  position: absolute;
  left: 15%;
  z-index: -100;
  top: -22px;
  transform: rotate(45deg);
}
.power-item::before {
  content: "";
  width: 20px;
  height: 30px;
  background: linear-gradient(45deg, #00bfff -109.94%, #3d0075 144.03%);
  position: absolute;
  right: 15%;
  z-index: -100;
  top: -22px;
  transform: rotate(135deg);
}
.power-item span {
  position: absolute;
  width: 60%;
  height: 60px;
  background: url("./../images/p.svg");
  background-position: center;
  background-size: cover;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  padding: 5px;
  font-weight: 700;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
}

.power-item .bottom-div {
  position: absolute;
  bottom: -20%;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 120px;
  border: 10px solid var(--blue);
  border-radius: 50%;
  background: linear-gradient(0deg, #ffffff -0.01%, #dfe0e0 99.99%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.power-item .bottom-div img {
  width: 60%;
  height: 60%;
}
