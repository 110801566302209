.partner-form-section {
  width: 100%;
  height: auto;
}
.partner-form-section .form-section {
  padding: 40px;
  margin-bottom: 30px;
  background-color: var(--gray);
}

.partner-form-section h1 {
  font-weight: 800;
  text-align: center;
  font-size: 35px;
  color: var(--blue);
}

.partner-form-section .input-group .btn {
  border-color: #dee2e6;
  background-color: #fff !important;
  color: #000;
}
.partner-form-section ul {
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  width: 90%;
}
.partner-form-section ul li {
  text-align: center;
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 10px;
  color: var(--red);
}

.partner-form-section img {
  width: 100%;
}

.third-sectin p {
  font-size: 19px;
  font-weight: 500;
}

.ourpartner-section {
  margin-top: 20px;
  padding: 20px;
}
.ourpartner-section h1 {
  font-weight: 800;
  text-align: center;
  font-size: 35px;
  color: var(--blue);
}

.ourpartner-section p {
  text-align: center;
  font-size: 18px;
  color: var(--blue);
}
.ourpartner-section p a {
  font-weight: 600;
  color: var(--blue);
  text-decoration: none;
}
.ourpartner-section .col-md-2,
.col-md-4 {
  display: flex;
  justify-content: center;
  align-items: center;
}
