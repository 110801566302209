@media (max-width: 420px) {
  .icon-nav {
    flex-direction: row !important;
  }
  .icon-nav a {
    padding: 10px 20px !important;
  }
  .team-section {
    height: auto !important;
  }
  .navbar-brand {
    margin: 0 !important;
  }

  /* Top Header */
  .top-header {
    height: 120px;
  }
  .top-header h1 {
    font-size: 4vw;
  }
  .top-header h3 {
    font-size: 4vw;
  }

  /* Mission */
  .Mission-section .img-div-left {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .Mission-section .img-div-right {
    clip-path: polygon(0% 0, 100% 0, 100% 100%, 0 100%);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .Mission-section .img-div-reverse {
    clip-path: polygon(0% 0, 100% 0, 100% 100%, 0 100%);
  }

  .Mission-section .col-md-6.reverse::before,
  .Mission-section .col-md-6.reverse::after {
    content: none;
  }
  .Mission-section .col-md-6.reverse::before,
  .Mission-section .col-md-6.reverse::after {
    content: none;
  }

  .Mission-section .img-div-reverse {
    height: auto;
    position: relative;
  }

  .Mission-section .col-md-7::after {
    content: none !important;
  }

  .Mission-section .col-md-7::before {
    content: none !important;
  }
  .Mission-section .right.col-md-7 {
    order: 1;
  }
  .Mission-section .right-md-5 {
    order: 2;
  }

  .Mission-section .text-section-mission {
    width: 100%;
    padding: 20px 20px 10px 70px;
    position: relative;
  }
  .Mission-section .text-section-mission h1 {
    font-size: 20px;
    font-weight: 700;
  }
  .Mission-section .text-section-mission h4 {
    width: 100%;
    font-size: 16px;
    line-height: inherit;
  }
  .Mission-section .img-div-right {
    height: auto;
    position: relative;
  }
  .Mission-section .img-div-left {
    height: auto;
    position: relative;
  }

  .Mission-section .img-div-left::after {
    position: absolute;
    content: "";
    width: 200px;
    height: 100%;
    top: 0;
    left: 10px;
    background-color: var(--red);
    clip-path: polygon(0 0, 0% 100%, 37% 0);
  }

  .Mission-section .img-div-right::after {
    position: absolute;
    content: "";
    width: 200px;
    height: 100%;
    top: 0;
    left: 10px;
    background-color: var(--red);
    clip-path: polygon(0 0, 0% 100%, 37% 0);
  }
  .Mission-section .text-section-mission::after {
    position: absolute;
    content: "";
    width: 200px;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--red);
    clip-path: polygon(0 0, 0% 100%, 37% 0);
  }
  .Mission-section .img-div-reverse::after {
    position: absolute;
    content: "";
    width: 200px;
    height: 100%;
    top: 0;
    right: 10px;
    background-color: var(--red);
    clip-path: polygon(0 0, 0% 100%, 37% 0);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  /* text section */
  .text-section p {
    width: 100% !important;
    font-size: 3.5vw;
  }

  /* VOTE SECTIN*/
  .vote-section {
    height: auto;
    flex-direction: column;
    padding: 20px 10px;
  }
  .vote-section .img-div {
    width: 100%;
  }
  .vote-section .text-div h1 {
    width: 100% !important;
    font-size: 5.5vw !important;
    text-align: left;
    margin-bottom: 0;
  }
  .vote-section .vote.text-div {
    width: 80% !important;
    margin: 0 auto;
    padding: 10px;
  }
  .vote-section .vote.text-div .btn {
    margin: 0 auto;
  }
  .vote-section .vote.text-div p {
    width: 100% !important;
    font-size: 4vw;
    text-align: inherit;
    margin: 10px 0;
    line-height: inherit;
  }
  .power-item {
    margin: 50px auto;
    height: 330px !important;
  }
  .power-item::before {
    right: 18%;
  }
  .power-item::after {
    left: 18%;
  }
  .power-item span {
    width: 55%;
  }
  .power-item .bottom-div {
    position: absolute;
    bottom: -25%;
    left: 50%;
    transform: translateX(-50%);
    width: 140px;
    height: 140px;
    border: 10px solid var(--blue);
    border-radius: 50%;
    background: linear-gradient(0deg, #ffffff -0.01%, #dfe0e0 99.99%);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Youth */
  .youth-section {
    height: auto;
  }

  .youth-section .col-md-6:nth-child(1) {
    order: 2;
  }
  .youth-section .col-md-6:nth-child(2) {
    order: 1;
  }
  .youth-section .text-youth-div {
    width: 100%;
    height: auto;
  }
  .youth-section .text-youth-div h1 {
    font-size: 7vw;
  }
  .youth-section .img-youth-div {
    height: auto;
  }
  .youth-section .text-youth-div p {
    font-size: 4vw;
  }

  /* MOMNET */
  .movement-section {
    height: auto;
    padding: 20px;
  }
  .movement-section h1 {
    font-size: 20px;
  }
  .movement-section p {
    text-align: center;
    font-size: 14px;
  }

  .togather-section .togather-form-section {
    width: 100%;
  }
  .togather-section .col-md-5 {
    text-align: center;
  }

  /* involed */
  .get-involed-section {
    height: 280px;
  }
  .get-involed-section .overlay p {
    width: 90%;
    margin: 10px auto;
    font-size: 4vw;
    line-height: 20px;
  }

  .valunteer-section h1 {
    font-size: 5vw;
  }
  .valunteer-text h1 {
    font-size: 5vw;
  }
  .valunteer-text p {
    font-size: 3vw;
    line-height: inherit;
    width: 100%;
  }

  /* Footer */
  .footer {
    position: inherit;
    height: auto;
  }
  .footer .footer-overlay {
    position: inherit;
    text-align: center;
  }

  /* slide */
  .slide-item {
    height: auto !important;
  }

  /* Protection */
  .service-section h4 {
    width: 100%;
    padding: 20px;
    font-size: 4vw;
    text-align: justify;
  }

  /* About Us */
  .about-section {
    text-align: center;
  }
  .about-top-image {
    margin-bottom: 30px;
  }

  .about-top .about-top-image {
    width: 100%;
    position: relative;
  }
  .about-text-section {
    padding: 20px 0;
  }

  .about-main-text ul li {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: justify;
  }
  .text-div h1 {
    font-weight: 600;
    text-align: justify;
    font-size: 22px;
  }

  .text-div p {
    font-weight: 700;
    text-align: center;
    font-size: 22px;
    line-height: 25px;
    margin: 20px 0;
  }
  .text-div ul {
    width: 100% !important;
    flex-direction: column;
  }
  .text-div ul li {
    width: 100%;
    justify-content: flex-start;
    height: 60px;
  }

  .about-text-section p {
    width: 100%;
    text-align: justify;
    margin-bottom: 20px;
    line-height: 25px;
  }

  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 15px !important;
    height: 15px !important;
    border-radius: 100px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    overflow: hidden;
  }

  /* service */
  .service-item .card .card-img-top {
    height: auto;
  }

  /* Impact */
  .impact {
    width: 100%;
    border-radius: 0%;
  }
  .impact p {
    width: 90%;
    text-align: center;
    font-weight: 500;
    color: #fff;
    font-size: 3vw;
  }

  /* Banker */
  .banker-section h1 {
    width: 100%;
    font-size: 20px;
  }
  .banker-section p {
    font-size: inherit;
    font-weight: 500;
    line-height: inherit;
    text-align: justify;
  }

  .form-section h1 {
    font-size: 6vw;
  }
  .partner-form-section h1 {
    font-size: 6vw;
  }
  .partner-form-section ul li {
    font-size: 4vw;
    text-align: justify;
  }

  .third-sectin p {
    font-size: inherit;
    text-align: justify;
  }
  .ourpartner-section {
    margin-top: 0;
  }
  .ourpartner-section h1 {
    font-size: 6vw;
  }

  .ourpartner-section p {
    font-size: inherit;
  }
}
/* MEDIA 576 */
@media (min-width: 421px) and (max-width: 576px) {
  .icon-nav {
    flex-direction: row !important;
  }
  .icon-nav a {
    padding: 10px 20px !important;
  }

  /* Top Header */

  .top-header {
    height: 200px;
    background-position: 100% 100%;
  }
  .top-header .overlay {
    padding: 20px !important;
  }
  .top-header h1 {
    font-size: 3.5vw;
  }
  .top-header h3 {
    font-size: 3.5vw;
  }
  .team-section {
    height: 220px !important;
  }

  /* text section */
  .text-section p {
    font-size: 3vw;
  }

  /* Mission */
  .Mission-section .img-div-left {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .Mission-section .img-div-right {
    clip-path: polygon(0% 0, 100% 0, 100% 100%, 0 100%);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .Mission-section .img-div-reverse {
    clip-path: polygon(0% 0, 100% 0, 100% 100%, 0 100%);
  }

  .Mission-section .col-md-6.reverse::before,
  .Mission-section .col-md-6.reverse::after {
    content: none;
  }
  .Mission-section .col-md-6.reverse::before,
  .Mission-section .col-md-6.reverse::after {
    content: none;
  }

  .Mission-section .img-div-reverse {
    height: auto;
    position: relative;
  }

  .Mission-section .col-md-7::after {
    content: none !important;
  }

  .Mission-section .col-md-7::before {
    content: none !important;
  }
  .Mission-section .right.col-md-7 {
    order: 1;
  }
  .Mission-section .right-md-5 {
    order: 2;
  }

  .Mission-section .text-section-mission {
    width: 100%;
    padding: 20px 20px 10px 70px;
    position: relative;
  }
  .Mission-section .text-section-mission h1 {
    font-size: 20px;
    font-weight: 700;
  }
  .Mission-section .text-section-mission h4 {
    width: 100%;
    font-size: 16px;
    line-height: inherit;
  }
  .Mission-section .img-div-right {
    height: auto;
    position: relative;
  }
  .Mission-section .img-div-left {
    height: auto;
    position: relative;
  }

  .Mission-section .img-div-left::after {
    position: absolute;
    content: "";
    width: 200px;
    height: 100%;
    top: 0;
    left: 10px;
    background-color: var(--red);
    clip-path: polygon(0 0, 0% 100%, 37% 0);
  }

  .Mission-section .img-div-right::after {
    position: absolute;
    content: "";
    width: 200px;
    height: 100%;
    top: 0;
    left: 10px;
    background-color: var(--red);
    clip-path: polygon(0 0, 0% 100%, 37% 0);
  }
  .Mission-section .text-section-mission::after {
    position: absolute;
    content: "";
    width: 200px;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--red);
    clip-path: polygon(0 0, 0% 100%, 37% 0);
  }
  .Mission-section .img-div-reverse::after {
    position: absolute;
    content: "";
    width: 200px;
    height: 100%;
    top: 0;
    right: 10px;
    background-color: var(--red);
    clip-path: polygon(0 0, 0% 100%, 37% 0);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  /* VOTE SECTIN*/
  .vote-section {
    height: auto;
    flex-direction: column;
    padding: 20px 10px;
  }
  .vote-section .img-div {
    width: 100%;
  }
  .vote-section .text-div h1 {
    width: 100% !important;
    font-size: 6vw !important;
  }
  .vote-section .vote.text-div {
    width: 100% !important;
    padding: 10px;
  }
  .vote-section .vote.text-div .btn {
    margin: 0 auto;
  }
  .vote-section .vote.text-div p {
    width: 100% !important;
    font-size: 6vw;
    margin-bottom: 60px;
  }

  /* Power */
  .power-item {
    margin-bottom: 100px;
  }

  /* Youth */
  .youth-section {
    height: auto;
  }
  .youth-section .text-youth-div {
    width: 100%;
    height: auto;
  }
  .youth-section .text-youth-div h1 {
    font-size: 6vw;
  }
  .youth-section .img-youth-div {
    height: auto;
  }

  /* MOMNET */
  .movement-section {
    padding: 20px;
  }
  .movement-section p {
    text-align: center;
  }

  .togather-section .togather-form-section {
    width: 100%;
  }

  /* involed */
  .get-involed-section {
    height: inherit;
  }
  .get-involed-section .overlay p {
    width: 90%;
    font-size: 12px;
    line-height: inherit;
    /* padding: 10px; */
  }

  /* Footer */
  .footer {
    position: inherit;
    height: auto;
  }
  .footer .footer-overlay {
    position: inherit;
  }

  /* slide */
  .slide-item {
    height: auto !important;
  }

  /* Protection */
  .service-section h4 {
    width: 100%;
    padding: 20px;
  }

  /* About Us */
  .about-top-image {
    margin-bottom: 30px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .icon-nav {
    flex-direction: row !important;
  }
  .icon-nav a {
    padding: 10px 20px !important;
  }

  /* Top Header */
  .top-header h1 {
    font-size: 3.3vw;
  }
  .top-header h3 {
    font-size: 3.5vw;
  }

  .team-section {
    height: 250px !important;
  }

  /* text section */
  .text-section p {
    font-size: 2.5vw;
  }

  /* Mission */
  .Mission-section .img-div-left {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .Mission-section .img-div-right {
    clip-path: polygon(0% 0, 100% 0, 100% 100%, 0 100%);
  }
  .Mission-section .img-div-reverse {
    clip-path: polygon(0% 0, 100% 0, 100% 100%, 0 100%);
  }
  .Mission-section .col-md-7::after {
    content: none !important;
  }
  .Mission-section .col-md-6.reverse::before,
  .Mission-section .col-md-6.reverse::after {
    content: none;
  }

  .Mission-section .col-md-7::before {
    content: none !important;
  }

  .Mission-section .text-section-mission {
    width: 100%;
    padding: 20px;
  }
  .Mission-section .text-section-mission h4 {
    width: 100%;
  }

  /* VOTE SECTIN*/
  .vote-section {
    height: auto;
    flex-direction: column;
    padding: 20px 10px;
  }
  .vote-section .img-div {
    width: 100%;
  }
  .vote-section .text-div h1 {
    width: 100% !important;
    font-size: 6vw !important;
  }
  .vote-section .vote.text-div {
    width: 100% !important;
    padding: 10px;
  }
  .vote-section .vote.text-div .btn {
    margin: 0 auto;
  }
  .vote-section .vote.text-div p {
    width: 100% !important;
    font-size: 6vw;
    margin-bottom: 60px;
  }
  .power-item {
    margin: 100px auto;
  }
  .power-item::before {
    right: 18%;
  }
  .power-item::after {
    left: 18%;
  }

  /* Youth */
  .youth-section {
    height: auto;
  }
  .youth-section .text-youth-div {
    width: 100%;
    height: auto;
  }
  .youth-section .text-youth-div h1 {
    font-size: 6vw;
  }
  .youth-section .img-youth-div {
    height: auto;
  }

  /* MOMNET */
  .movement-section {
    padding: 20px;
  }
  .movement-section p {
    text-align: center;
  }

  .togather-section .togather-form-section {
    width: 100%;
  }
  .togather-section .col-md-5 {
    text-align: center;
  }

  /* involed */
  .get-involed-section {
    height: 300px;
  }
  .get-involed-section .overlay p {
    width: 90%;
    margin: 10px auto;
    font-size: 3vw;
  }

  /* Footer */
  .footer {
    position: inherit;
    height: auto;
  }
  .footer .footer-overlay {
    position: inherit;
  }

  /* slide */
  .slide-item {
    height: auto !important;
  }

  /* Protection */
  .service-section h4 {
    width: 100%;
    padding: 20px;
  }

  /* About Us */
  .about-section {
    text-align: center;
  }
  .about-top-image {
    margin-bottom: 30px;
  }

  .about-top .about-top-image {
    width: 90%;
    height: 350px;
    position: relative;
  }
}

/* 992 */

@media (min-width: 768px) and (max-width: 992px) {
  .icon-nav {
    flex-direction: row !important;
  }
  .icon-nav a {
    padding: 10px 20px !important;
  }

  .top-header {
    background-position: 100% 100%;
  }

  /* Top Header */
  .top-header h1 {
    font-size: 3.3vw;
  }
  .top-header h3 {
    font-size: 3.5vw;
  }

  .team-section {
    height: 250px !important;
  }

  /* text section */
  .text-section p {
    font-size: 3vw;
    width: 100% !important;
  }

  /* Mission */
  .Mission-section .img-div-left {
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .Mission-section .img-div-right {
    height: 100%;
    clip-path: polygon(0% 0, 100% 0, 100% 100%, 0 100%);
  }
  .Mission-section .img-div-reverse {
    height: 100%;
    clip-path: polygon(0% 0, 100% 0, 100% 100%, 0 100%);
  }
  .Mission-section .col-md-7::after {
    content: none !important;
  }
  .Mission-section .col-md-6.reverse::before,
  .Mission-section .col-md-6.reverse::after {
    content: none;
  }

  .Mission-section .col-md-7::before {
    content: none !important;
  }

  .Mission-section .text-section-mission {
    width: 100%;
    padding: 10px;
  }
  .Mission-section .text-section-mission h4 {
    width: 100%;
    line-height: inherit;
    font-size: 2.5vw;
  }

  /* VOTE SECTIN*/
  .vote-section {
    height: auto;
    flex-direction: column;
    padding: 20px 10px;
  }
  .vote-section .img-div {
    width: 100%;
  }
  .vote-section .text-div h1 {
    width: 100% !important;
    font-size: 6vw !important;
  }
  .vote-section .vote.text-div {
    width: 100% !important;
    padding: 10px;
  }
  .vote-section .vote.text-div .btn {
    margin: 0 auto;
  }
  .vote-section .vote.text-div p {
    width: 100% !important;
    font-size: 6vw;
    margin-bottom: 60px;
  }
  .power-item {
    margin: 100px auto;
  }
  .power-item::before {
    right: 18%;
  }
  .power-item::after {
    left: 18%;
  }

  /* Youth */
  .youth-section {
    height: auto;
  }
  .youth-section .text-youth-div {
    width: 100%;
    height: auto;
  }
  .youth-section .text-youth-div p {
    font-size: 26px;
    margin-top: 20px;
    text-align: center;
  }
  .youth-section .text-youth-div h1 {
    font-size: 6vw;
    text-align: center;
  }
  .youth-section .img-youth-div {
    height: auto;
  }

  /* MOMNET */
  .movement-section {
    padding: 20px;
  }
  .movement-section h1 {
    font-size: 6vw;
  }
  .movement-section p {
    text-align: center;
    font-size: 4vw;
  }

  .about-text-section p {
    font-size: 3vw;
    text-align: justify;
  }

  .togather-section .togather-form-section {
    width: 100%;
  }
  .togather-section .col-md-5 {
    text-align: center;
  }

  /* involed */
  .get-involed-section {
    height: 300px;
  }
  .get-involed-section .overlay p {
    width: 90%;
    margin: 10px auto;
    font-size: 3vw;
  }

  /* Footer */
  .footer {
    position: inherit;
    height: auto;
  }
  .footer .footer-overlay {
    position: inherit;
  }

  /* slide */
  .slide-item {
    height: auto !important;
  }

  /* Protection */
  .service-section h4 {
    width: 100%;
    padding: 20px;
  }

  /* About Us */
  .about-section {
    text-align: center;
  }
  .about-top-image {
    margin-bottom: 30px;
  }

  .about-top .about-top-image {
    width: 90%;
    height: 350px;
    position: relative;
  }
}
