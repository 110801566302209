.service-section {
  width: 95%;
  margin: 0 auto;
  min-height: 500px;
  padding: 20px;
}
.service-section h4 {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
  color: var(--red);
  font-weight: 700;
  padding: 30px;
}

/* SERVICE ITEM */
.service-item {
  width: 100%;
  margin: 0 auto;
}
.service-item .card {
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 20px !important;
  overflow: hidden;
}
.service-item .card .card-title {
  text-align: center !important;
  font-weight: 800;
}
.service-item .card .card-img-top {
  height: 350px;
}
.service-item .card .card-text {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  height: 200px !important;
}
