.get-involed-section {
  width: 100%;
  height: 500px;
  position: relative;
}
.get-involed-section img {
  width: 100%;
  height: 100%;
}
.get-involed-section .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.get-involed-section .overlay h1 {
  color: #fff;
  font-weight: 800;
}
.get-involed-section .overlay p {
  width: 50%;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  color: #fff;
  margin-bottom: 30px;
  text-align: center;
}
