.moment-section {
  width: 100%;
  min-height: 500px;
  padding: 40px;
  background-color: var(--gray);
  margin-bottom: 30px;
}
.moment-section .form-section {
  margin: 30px 0;
}
.moment-section .form-section a {
  color: #212529bf;
}
.moment-section .input-group {
  border-radius: 5px;
}
.moment-section .input-group .btn {
  border-color: #dee2e6;
  background-color: #fff !important;
  color: #000;
}

.banker-section {
  width: 100%;
  min-height: 300px;
  padding: 50px 30px;
}

.banker-section h1 {
  font-weight: 800;
  width: 70%;
  margin-top: 30px;
  color: var(--blue);
}
.banker-section p {
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
}
