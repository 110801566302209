.top-header {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: url("./../images/top-bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  height: 220px;
  position: relative;
}
.top-header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--op-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.top-header h1 {
  font-size: 2.2vw;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
.top-header h3 {
  color: var(--red);
  text-transform: uppercase;
  font-size: 2.2vw;
  font-weight: 700;
}

/* Team */
.team-section {
  width: 100%;
  height: 450px;
}
.team-section img {
  width: 100%;
  height: 100%;
}

/* TExt Section */
.text-section {
  width: 100%;
  background-color: var(--red);
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-section p {
  width: 90%;
  font-size: 1.5vw;
  color: #fff;
  font-weight: 600;
  text-align: center;
}
