.valunteer-section {
  padding: 30px;
  width: 100%;
  background-color: var(--gray);
}
.valunteer-section h1 {
  font-weight: 800;
  color: var(--blue);
  font-size: 35px;
  text-align: center;
  margin-bottom: 20px;
}

.valunteer-section .input-group .btn {
  border-color: #dee2e6;
  background-color: #fff !important;
  color: #000;
}

.valunteer-text {
  width: 100%;
  text-align: center;
  padding: 30px;
}

.valunteer-text h1 {
  font-weight: 800;
  color: var(--blue);
  font-size: 35px;
  text-align: center;
}

.valunteer-text p {
  font-weight: 700;
  width: 90%;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  margin: 20px auto;
}
.valunteer-text span {
  font-weight: 700;
  text-align: center;
  font-size: 16px;
  color: var(--red);
}
