.navbar {
  padding: 20px 30px !important;
  background-color: var(--blue) !important;
}
.navbar-brand {
  margin-right: 100px !important;
}

.navbar .me-auto.navbar-nav .nav-link {
  padding: 10px 25px !important;
  text-transform: uppercase !important;
  font-weight: 400;
  font-size: 14px !important;
  font-weight: 500;
}
.navbar .nav-link {
  color: #fff !important;
}
.navbar .nav-link:focus,
.navbar .nav-link:hover {
  background-color: #38457a !important;
}
.navbar .active {
  font-weight: 600 !important;
  background-color: #38457a !important;
}
.icon-nav {
  margin-right: 100px;
}
.dropdown-menu {
  background-color: var(--blue) !important;
  border: none !important;
}
.dropdown-item {
  color: #fff !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #38457a !important;
}
