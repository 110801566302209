.vote-section {
  width: 100%;
  height: 600px;
  background-color: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
}
.vote-section .vote {
  width: 50%;
  height: 100%;
}
.vote-section .vote.img-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vote-section .vote.img-div img {
  width: 80%;
  height: 85%;
}

.vote-section .vote.text-div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 40px;
}
.vote-section .vote.text-div h1 {
  font-weight: 800;
  width: 80%;
  font-size: 40px;
  color: var(--red);
}
.vote-section .vote.text-div p {
  font-weight: 600;
  width: 80%;
  font-size: 25px;
}
