.about-section {
  width: 100%;
  padding: 50px 30px;
}
.about-text-section {
  padding: 20px;
}
.about-text-section h1 {
  color: var(--blue);
  font-weight: 700;
}
.about-text-section p {
  font-weight: 700;
  line-height: 30px;
  margin: 30px 0;
}

/* About page top */
.about-top {
  width: 100%;
  padding: 20px;
  margin: 20px 0;
}

.about-top .about-top-image {
  width: 100%;
  height: 300px;
  position: relative;
}
.about-top .about-top-image img {
  width: 100%;
  height: 100%;
}
.about-top .about-text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.about-top .about-text-overlay p {
  color: #fff;
  text-align: center;
  font-weight: 500;
  line-height: 30px;
  font-size: 18px;
}

.about-main img {
  width: 100%;
}
.about-main-text h2 {
  padding: 10px 0;
  font-weight: 600;
  color: var(--red);
}
.about-main-text ul {
  padding-left: 15px;
}
.about-main-text ul li {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}

.text-div {
  width: 100%;
  margin-top: 30px;
}
.text-div h1 {
  font-weight: 700;
  text-align: center;
  font-size: 35px;
}
.text-div h1 span {
  color: var(--red);
}
.text-div p {
  font-weight: 700;
  text-align: center;
  font-size: 35px;
  color: var(--blue);
}

.text-div ul {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 0 auto;
  padding: 30px;
  list-style: none;
}
.text-div ul li {
  width: 200px;
  font-weight: 600;
  color: var(--red);
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-div ul li img {
  margin-right: 10px;
}
