@import url("fonts.css");
@import url("variables.css");
@import url("header.css");
@import url("topheader.css");
@import url("mission.css");
@import url("vote.css");
@import url("power.css");
@import url("youth.css");
@import url("togather.css");
@import url("get.css");
@import url("footer.css");
@import url("about.css");
@import url("gallary.css");
@import url("slide.css");
@import url("services.css");
@import url("impact.css");
@import url("moment.css");
@import url("valunteer.css");
@import url("partner.css");
@import url("responsive.css");
* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
}
.wrap {
  width: 100%;
  overflow: hidden;
}

.btn-danger {
  width: 200px;
  height: 40px;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: 1px;
  background-color: var(--red) !important;
}

.glassy-btn {
  width: 250px;
  height: 50px;
  color: #fff;
  border: 1px solid #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 10px;
  background-color: transparent !important;
}
