.impact {
  width: 90%;
  height: auto;
  background: url("./../images/impact.png") no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  margin: 0 auto;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 20px;
}
.impact h1 {
  font-weight: 800;
  color: #fff;
}

.impact p {
  width: 80%;
  text-align: center;
  font-weight: 500;
  color: #fff;
  font-size: 1.5vw;
}
