.togather-section {
  width: 100%;
  padding: 10px;
}
.togather-section h1 {
  text-align: center;
  font-weight: 800;
  padding: 20px 0;
  color: var(--blue);
  margin-bottom: 30px;
}
.togather-section .togather-form-section {
  width: 80%;
  padding: 20px;
  margin: 0 auto;
}

.togather-section .togather-form-section p {
  font-weight: 600;
  margin-bottom: 30px;
}
.togather-section .togather-form-section .input-group-text {
  background-color: transparent !important;
  border: none;
}
.togather-section .togather-form-section .form-control {
  font-size: 14px;
  border: 0;
}
.togather-section .togather-form-section .form-control:focus,
.togather-section .togather-form-section .form-control:hover {
  box-shadow: none !important;
}
.togather-section .togather-form-section .form-control::placeholder {
  font-size: 14px;
  font-weight: 500;
}
.togather-section .togather-form-section .input-group {
  border: 1px solid var(--red);
  overflow: hidden;
  border-radius: 8px;
}
.togather-section .togather-form-section .form-label {
  color: var(--blue);
  font-weight: 500;
  margin-top: 20px;
}
